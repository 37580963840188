import _ from "lodash";

export const getSeconds = totalSeconds => {
  return Math.round(totalSeconds % 60);
};

export const getMinutes = totalSeconds => {
  return _.floor((totalSeconds / 60) % 60);
};

export const getHours = totalSeconds => {
  return _.floor(totalSeconds / 3600);
};
