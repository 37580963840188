import React from "react";
import Home from "./Home.js";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { withAuthenticationRequired } from "@auth0/auth0-react";

const App = () => {
  return (
    <Router>
      <Switch>
        <Route exact path="/" component={withAuthenticationRequired(Home)} />
      </Switch>
    </Router>
  );
};

export default App;
