import axios from "axios";
import _ from "lodash";
import { JWT_ACCESS_TOKEN, USER_ID } from "../authentication/helpers";

export const uploadVideoToAssetManager = (payload) => {
  payload.user_id = localStorage.getItem(USER_ID);
  return axios
    .post("/doctor/video", payload, {
      baseURL: process.env.REACT_APP_BACKEND_API_HOST,
      headers: {
        Authorization: `Bearer ${localStorage.getItem(JWT_ACCESS_TOKEN)}`,
      },
    })
    .then((_) => console.log(`Uploaded ${payload.filename} successfully!`))
    .catch((err) => {
      const errorMsg = _.get(
        err.response,
        ["data", "message"],
        "An unknown error has occurred"
      );
      alert(errorMsg);
    });
};

export const stitchVideosInAWS = (videoUris) => {
  const payload = {
    input_files_uri: videoUris,
  };

  return axios
    .post("/video/stitch-videos", payload, {
      baseURL: process.env.REACT_APP_BACKEND_API_HOST,
      headers: {
        Authorization: `Bearer ${localStorage.getItem(JWT_ACCESS_TOKEN)}`,
      },
    })
    .then((response) => {
      return _.get(
        response,
        "data.stitched_video_uri",
        "No stitched_video_uri received"
      );
    })
    .catch((err) => {
      const errorMsg = _.get(
        err.response,
        ["data", "message"],
        "An unknown error has occurred"
      );
      alert(errorMsg);
    });
};

export const getPreSignedUrl = (filename, s3BucketName) => {
  return axios
    .get(
      `/media/upload-url?filename=${filename}&s3BucketName=${s3BucketName}`,
      {
        baseURL: process.env.REACT_APP_BACKEND_API_HOST,
        headers: {
          Authorization: `Bearer ${localStorage.getItem(JWT_ACCESS_TOKEN)}`,
        },
      }
    )
    .then((res) => {
      console.log(`Got presignedurl successfully!`);
      return res.data.url;
    })
    .catch((err) => {
      const errorMsg = _.get(
        err.response,
        ["data", "message"],
        "An unknown error has occurred"
      );
      alert(errorMsg);
    });
};

export const uploadFileToS3 = (url, payload, config) => {
  return axios
    .put(url, payload, config)
    .then((res) => {
      console.log(`Uploaded file successfully!`);
      return res;
    })
    .catch((err) => {
      const errorMsg = _.get(
        err.response,
        ["data", "message"],
        "An unknown error has occurred"
      );
      alert(errorMsg);
    });
};
