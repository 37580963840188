import jwt_decode from "jwt-decode";

export const JWT_ACCESS_TOKEN = "jwtAccessToken";
export const USER_ID = "userId";
export const NAMESPACE = "https://upload.kaliber.ai//roles";
export const ROLE_DOCTOR = "Doctor";
export const ROLE_KALIBER_ADMIN = "Kaliber Admin";

export const setAuthToken = (accessToken) => {
  localStorage.setItem(JWT_ACCESS_TOKEN, accessToken);
};

export const setUserId = (userId) => {
  localStorage.setItem(USER_ID, userId);
};

export const isKaliberAdminOrDoctor = (accessToken) => {
  // Return false if the access token is empty
  if (!accessToken) {
    return false;
  }
  const decoded = jwt_decode(accessToken);
  const roles = decoded[NAMESPACE];
  if (!roles.includes(ROLE_DOCTOR) && !roles.includes(ROLE_KALIBER_ADMIN)) {
    return false;
  }
  return true;
};
