import SparkMD5 from "spark-md5";

export const isVideo = (fname) => {
  return fname.toLowerCase().match(/\.((avi)|(mpeg)|(mpg)|(mov)|(mp4))$/g);
};

export const isImage = (fname) => {
  return fname.toLowerCase().match(/\.((jpg)|(png)|(jpeg)|(bmp)|(gif))$/g);
};

export const isZip = (fname) => {
  return fname.toLowerCase().match(/\.zip$/i) !== null;
};

export const isCSV = (fname) => {
  return fname.toLowerCase().match(/\.csv$/i) !== null;
};

export const getParentDir = (path) => {
  const parentDirs = path.split("/");
  if (parentDirs.length > 1) {
    return parentDirs[parentDirs.length - 2];
  }
  return null;
};

export const findKeyWord = (filename) => {
  let keyword = "";
  let resultName = "";
  const newFileName = filename.toString();
  if (newFileName.includes("segment")) {
    keyword = "segment";
  }
  if (newFileName.includes("vid")) {
    keyword = "vid";
  }
  if (newFileName.includes("video")) {
    keyword = "video";
  }

  let startIndex = 0;
  if (keyword === "") {
    startIndex = newFileName.search(/\d/);
  } else {
    startIndex = newFileName.indexOf(keyword) + keyword.length;
  }

  for (let i = startIndex; i < newFileName.length; i++) {
    const c = newFileName.charAt(i);
    if (isNaN(c)) {
      break;
    }
    resultName = resultName + c.toString();
  }

  return resultName;
};

export const getFilename = (file, dateString, index) => {
  const fileExtension = file.name.split(".").pop();
  let filePrefix = "asset-";
  if (isVideo(file.path)) {
    filePrefix = "video-";
  }
  if (isImage(file.path)) {
    filePrefix = "image-";
  }
  return (
    filePrefix + index + "-" + dateString.split(".")[1] + "." + fileExtension
  );
};

/**
 * Computes the MD5 checksum of the provided File object. Commonly used to check the integrity of a file after it has
 * been uploaded/processed.
 * @param file {File}
 */
export const computeChecksumMd5 = (file) => {
  return new Promise((resolve, reject) => {
    const chunkSize = 2097152; // Read in chunks of 2MB
    const spark = new SparkMD5.ArrayBuffer();
    const fileReader = new FileReader();

    let cursor = 0; // current cursor in file

    fileReader.onerror = function () {
      reject("MD5 computation failed - error reading the file");
    };

    // read chunk starting at `cursor` into memory
    function processChunk(chunk_start) {
      const chunk_end = Math.min(file.size, chunk_start + chunkSize);
      fileReader.readAsArrayBuffer(file.slice(chunk_start, chunk_end));
    }

    // when it's available in memory, process it
    fileReader.onload = function (e) {
      spark.append(e.target.result); // Accumulate chunk to md5 computation
      cursor += chunkSize; // Move past this chunk

      if (cursor < file.size) {
        // Enqueue next chunk to be accumulated
        processChunk(cursor);
      } else {
        // Computation ended, last chunk has been processed. Return as Promise value.
        // This returns the hexdigest form of md5 hash
        resolve(spark.end());
      }
    };

    processChunk(0);
  });
};
